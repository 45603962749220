@import '../../App';

.holidays-note {
  align-items: center;
  border-radius: 2px;
  display: flex;
  background: $blueb25;
  padding: 5px 10px;
  vertical-align: middle;

  p {
    margin: 0px;
    padding: 5px 38px 5px 0px;
  }

  &.edit-note {
    padding-bottom: 18px;
    margin-top: -5px;
  }

  svg:not(.action) {
    font-size: 30px;
    border: 1px solid transparent;
    border-radius: 50%;
    margin-right: 10px;
    color: $blueb100;
  }

  .action-container {
    position: absolute;
    right: 34px;
  }

  .icon-wrapper .action {
    cursor: pointer;
  }
  .input-form-control {
    width: 88%;

    .resizableTextArea {
      height: 100px !important;
      width: 100% !important;
    }
  }
}
