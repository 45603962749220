@import '../../App';

.form-input-box {
  padding: 5px 20px;

  .MuiFormControl-marginDense {
    margin-top: 8px !important;
  }
}

.text-box-style {
  width: 100%;
}

.interview-label {
  min-height: 28px;
  padding-bottom: 5px;
  display: block;
}

.link-button {
  margin-left: 20px !important;
  padding-top: 7px;
  display: inline-block;
  cursor: pointer;
}

.interview-status-icon {
  font-size: 30px !important;
  color: $black75;
}

.interview_status_row .form-input-box .MuiFormControl-marginDense {
  margin-top: 0px !important;
}