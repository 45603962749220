.applicant-filter {
    .css-26l3qy-menu {
        overflow-y: auto !important;
        height: 180px !important;
        position: relative !important;
    }
    
    .css-4ljt47-MenuList {
        height: 100% !important;
        overflow-y: auto !important;
    }
}