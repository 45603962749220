// Variables

// Font families
$archivo: "Archivo Regular", -apple-system, BlinkMacSystemFont, "Roboto",
  "Helvetica Neue", sans-serif;

$archivo--medium: "Archivo Medium", -apple-system, BlinkMacSystemFont, "Roboto",
  "Helvetica Neue", sans-serif;

$archivo--mediumitalic: "Archivo Medium Italic", -apple-system,
  BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;

$archivo--semi: "Archivo SemiBold", -apple-system, BlinkMacSystemFont, "Roboto",
  "Helvetica Neue", sans-serif;

$archivo--semiitalic: "Archivo SemiBold Italic", -apple-system,
  BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;

$archivo--bold: "Archivo Bold", -apple-system, BlinkMacSystemFont, "Roboto",
  "Helvetica Neue", sans-serif;

$archivo--bolditalic: "Archivo Bold Italic", -apple-system, BlinkMacSystemFont,
  "Roboto", "Helvetica Neue", sans-serif;

// MWF Font weights
$bold: 700;
$semi-bold: 600;
$medium-bold: 500;
$regular-bold: 400;
$mobile: 320px;
$mobileM: 375px;
$mobileL: 480px;
$tablet: 640px;
$tabletM: 800px;
$laptop: 960px;
$laptopM: 1280px;
$laptopL: 1440px;
$laptopXL: 1920px;

// Colors (You will most likely have to edit these on a project to project basis)
$black: #000;
$black25: #999999;
$black50: #666666;
$black75: #333333;
$black90: #010101;
$blackShadow: #0000000a;

$white: #FFF;
$white1: #e1e8ed;
$sidebarbg: rgba(24,139,246,.1);

$transparent: transparent;
$lightversion: #f1f4f6;

$navy25: #C3BFD6;
$navy50: #8780AE;
$navy75: #4C4085;
$navy100: #10005D;

$green25: #E6EDE5;
$green50: #CDDBCA;
$green75: #B4C9B0;
$green100: #216115; // #216115;  // 9BB796

$yellow25: #F8F0DC;
$yellow50: #F1E2B9;
$yellow75: #EBD396;
$yellow100:#FCC227;
$yellowp1: #FFA014;

$orange25: #FFD2CF;
$orange50: #FFA5A0;
$orange75: #FF7870;
$orange100: #FF4B41;  // 880f08 // FF4B41

$red25: #ECC7C9;
$red50: #D98E92;
$red75: #C7565C;
$red90: #ca1c1c;
$red100: #B41E26;

$maroon1: 	#800000;

$blue25: #C3CCE5;
$blue50: #8898CB;
$blue75: #4C65B1;
$blue100: #113297;

$blueb25: #cbdcf2;
$blueb100: #144fa8;


