@import '../../App';

.filter {
  &- {
    &label-min {
      min-height: 16px;
      display: inline-block;
    }
    &formControl {
      min-width: 120px;
      width: 100%;
    }
  }
}

.textBoxStyle {
  border-color: $white1 !important;

  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 0.25rem !important;
      border-color: $white1;
      font-size: 14px !important;
      color: $black50;
    }

    &.Mui-focused fieldset {
      border: 1px solid !important;
      border-color: $white1 !important;
      outline: none !important;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: $white1;
  }
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.filter-accordion {
  margin-top: 30px;
  margin-bottom: 60px;

  .accordion-header {
    display: flex;
  }

  .accordion-content {
    padding: 0px 0px 0px;
  }

  #panel1a-header {
    background: #fff;
    width: 100%;
    padding: 0px 6px;
  }
}
