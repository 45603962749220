@import '../../App';

.form-fieldset {
  padding: 12px;
  border-radius: 2px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 6px $white1;

  h5.fieldset-label {
    font-size: 18px;
    font-weight: $bold;
  }

  .link-icon-button {
    background-color: $black90 !important;
    border: 1px solid $black90 !important;
    color: $white !important;
    text-transform: capitalize !important;
    padding: 2px !important;
    font-size: 13px !important;
    line-height: 0 !important;
    border-radius: 0.2rem !important;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      box-shadow: 0px 5px 10px 0 $blackShadow !important;
    }
  }

  .company-avatar-wrapper {
    display: flex;
    justify-content: normal;
    margin-bottom: 20px;
    align-items: center;

    img {
      margin: 0px 16px;
      margin-top: -16px;
      max-width: 100px;
      max-height: 34px;
    }
    .MuiBox-root.MuiBox-root-8 h5 {
      padding-top: 2px;
    }
  }

  .form-avatar {
    margin-right: 20px;
    background-color: $blue75;
  }

  .file-upload-control {
    span.MuiIconButton-root {
      border: 1px solid $black25;
      height: 88px;
      width: 88px;
      padding: 0px !important;
      border-radius: 0 !important;

      .MuiIconButton-label {
        overflow: hidden;
      }
    }

    svg {
      font-size: 50px;
    }
  }

  .display-avatar-logo {
    width: 100%;
    max-width: 85px !important;
  }

  .input-file-button {
    display: none;
  }

  .border-none fieldset {
    border: none !important;
  }

  label {
    font-size: 14px !important;
  }
}

input:read-only {
  background: #f1f1f1;

  fieldset {
    border: none !important;
  }
}

textarea:read-only {
  background: #f1f1f1;

  &:focus-visible {
    border: none !important;
  }
}
.form-input-box {
  padding: 5px 20px;

  div {
    border-color: $white1 !important;
  }

  .multiSelectStyle {
    background: $white !important;
    width: 100%;
    // z-index: 2;
    border-color: $white1 !important;

    &:hover {
      border-color: $white1 !important;
    }

    .css-yk16xz-control {
      min-height: 40px !important;
    }

    .css-26l3qy-menu {
      margin-top: 4px !important;
    }

    .css-1pahdxg-control {
      background-color: $white !important;
      border-color: $white1 !important;
      box-shadow: none !important;
      min-height: 40px !important;
    }

    ::-webkit-scrollbar {
      width: 4px;
      scroll-behavior: smooth !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $black25;
      border-radius: 2px;
      scroll-behavior: smooth !important;
    }
  }

  .z-index-2 {
    z-index: 2 !important;
  }

  .z-index-3 {
    z-index: 3 !important;
  }

  .z-index-4 {
    z-index: 4 !important;
  }

  .input-form-control {
    width: 100% !important;
  }

  .react-datepicker__input-container input {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid $white1;
    height: 38px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover {
    border-radius: 0.3rem;
    background-color: $yellowp1;
    color: #fff;
  }
}

.form-input-box.px-0 {
  padding-left: 0px !important;
}

.file-upload-element {
  button {
    height: 30px !important;
    margin: 5px 0px !important;
    font-size: 12px !important;
  }
}

.file-upload-element.jd-upload {
  span.MuiIconButton-root {
    border: 1px solid !important;
    border-radius: 0px !important;
    height: 100px !important;
    width: 120px !important;
    padding: 0px !important;
    .MuiIconButton-label {
      overflow: hidden;
    }
  }
}

.permission-fieldset {
  label.MuiFormControlLabel-labelPlacementStart {
    justify-content: space-between !important;
  }
  span.MuiFormControlLabel-label {
    font-size: 14px !important;
  }

  .form-input-box {
    padding: 0px 20px !important;
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: inherit !important;
  }
}

.form-control-image {
  width: 88px;
}
.company-avatar-logo {
  width: 70px;
  border-radius: 6px;
}

.hide-label {
  visibility: hidden;
}

.file-upload-text {
  font-size: 16px !important;
}

.interview_status_row {
  .form-input-box {
    padding-right: 0px !important;
  }

  .resizableTextArea {
    margin-top: 0px !important;
    min-height: 100px !important;
    max-width: 100%;
  }

  div {
    border-color: $white1;
  }
}

.interview_status_row > div.form-input-box {
  padding-right: 0px !important;
}

.textBoxStyle {
  div {
    border-color: $white1 !important;
  }

  .css-yk16xz-control {
    min-height: 40px;
  }
}

.update-password-card {
  margin: 30px 0px 10px;
}

.resizableTextArea {
  padding: 10px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid $white1 !important;
  max-width: 100%;
}

.text-yellow {
  color: $yellowp1;
}

.file-container {
  width: 160px;
  margin-top: 18px;
}

.document-view-label {
  margin-left: 0px;
  padding-left: 0px !important;
  font-size: 18px;
}