// Typography declaration

body {
  font-family: $archivo;
  font-size: 16px;
  line-height: 22px;
}

p {
  font-family: $archivo;
  font-size: 1em;
  margin: 0 0 6px 0;
  padding: 0;
}
