@import '../../App';

.table {
  overflow-x: auto;
  width: 100% !important;

  thead {
    background: $white;
  }

  th {
    font-weight: 600;
    word-break: break-word;
  }

  td {
    word-break: break-word;
  }

  .MuiTableRow-root {
    border-top: 1px solid $lightversion;
    border-bottom: 4px solid $lightversion;
  }
  .w-10 {
    width: 10%;
  }
  span.badge-outlined {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  .w-80 {
    min-width: 80px;
  }

  .w-100 {
    min-width: 100px;
  }

  .w-150 {
    min-width: 150px;
  }

  .w-200 {
    min-width: 200px;
  }

  .w-132 {
    min-width: 132px;
  }

  .action-head {
    padding-left: 25px;
  }

  .icon-wrapper {
    cursor: pointer;
    margin: 0px 2px;
    display: inline-block;
    padding-top: 3px;
  }

  tbody tr td:not(:last-child) {
    cursor: pointer;
  }

  &- {
    &pagination {
      position: absolute;
      margin: 35px 0px;
      text-align: center;
      left: 50%;
    }

    &rows-counter {
      position: absolute;
      margin-top: 41px;
      font-size: 15px;
    }
  }

  .avatar-logo.profile-image {
    width: 100%;
    max-width: 50px;
  }
  .name-phone-group {
    display: block !important;
  }

  .link-color {
    color: $blue75;
    padding: 20px 50px;

    &:hover {
      color: $blue50 !important;
      text-decoration: underline !important;
    }
  }

  .highlighted-link {
    color: $blue75;

    &:hover {
      color: $blue50 !important;
      text-decoration: underline !important;
    }
  }

  .input-form-control {
    width: 80%;
  }
}

.positions-table {
  tr td:not(:nth-child(4)):not(:nth-child(7)) {
    cursor: default !important;
  }
}

.top-pagination {
  background-color: transparent !important;
  padding: 0px 30px 40px;

  .table-rows-counter {
    margin-left: -27px;
    position: absolute;
    margin-top: 17px;
    font-size: 15px;
  }

  .table-pagination {
    position: absolute;
    margin-left: 4px;
    left: 50% !important;
    margin-top: 10px;
  }
}

/* Start: Scrollbar */
.MuiTableContainer-root::-webkit-scrollbar {
  width: 2px;
  height: 12px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: $black25;
  border-radius: 2px;
  scroll-behavior: smooth !important;
}
/* End: scrollbar */

