@import '../../App.scss';

.sign {
  padding-top: 10px;
  display: flex !important;
  align-items: center !important;
  height: 100vh;

  .MuiGrid-item {
    margin: auto;
  }

  .text-box {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .heading {
    color: $black90;
    font-weight: $bold !important;
  }

  .subheading {
    color: $black50;
    font-size: 14px !important;
  }

  .input-form-control svg {
    color: $black50 !important;
    font-size: 18px !important;
  }

  .mx-21 {
    margin-left: 21px;
    margin-right: 21px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: $yellow100 !important;
    }
  }
  .center-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success {
    color: #166716;
  }

  .brand-logo {
    padding-left: 20px;

    img {
      max-width: 130px;
    }
  }
}
