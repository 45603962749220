/* archivo-regular - latin */
@font-face {
  font-family: "Archivo Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/archivo-v7-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Archivo Regular"), local("Archivo-Regular"),
    url("../assets/fonts/archivo-v7-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-regular.svg#Archivo")
      format("svg"); /* Legacy iOS */
}
/* archivo-500 - latin */
@font-face {
  font-family: "Archivo Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/archivo-v7-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Archivo Medium"), local("Archivo-Medium"),
    url("../assets/fonts/archivo-v7-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/archivo-v7-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-500.svg#Archivo")
      format("svg"); /* Legacy iOS */
}
/* archivo-500italic - latin */
@font-face {
  font-family: "Archivo Medium Italic";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/archivo-v7-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local("Archivo Medium Italic"), local("Archivo-MediumItalic"),
    url("../assets/fonts/archivo-v7-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-500italic.svg#Archivo")
      format("svg"); /* Legacy iOS */
}

/* archivo-600 - latin */
@font-face {
  font-family: "Archivo SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/archivo-v7-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Archivo SemiBold"), local("Archivo-SemiBold"),
    url("../assets/fonts/archivo-v7-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/archivo-v7-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-600.svg#Archivo")
      format("svg"); /* Legacy iOS */
}
/* archivo-600italic - latin */
@font-face {
  font-family: "Archivo SemiBold Italic";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/archivo-v7-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local("Archivo SemiBold Italic"), local("Archivo-SemiBoldItalic"),
    url("../assets/fonts/archivo-v7-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-600italic.svg#Archivo")
      format("svg"); /* Legacy iOS */
}

/* archivo-700 - latin */
@font-face {
  font-family: "Archivo Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/archivo-v7-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Archivo Bold"), local("Archivo-Bold"),
    url("../assets/fonts/archivo-v7-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/archivo-v7-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-700.svg#Archivo")
      format("svg"); /* Legacy iOS */
}
/* archivo-700italic - latin */
@font-face {
  font-family: "Archivo Bold Italic";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/archivo-v7-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("Archivo Bold Italic"), local("Archivo-BoldItalic"),
    url("../assets/fonts/archivo-v7-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/archivo-v7-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/archivo-v7-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../assets/fonts/archivo-v7-latin-700italic.svg#Archivo")
      format("svg"); /* Legacy iOS */
}
